import React from 'react';

import { StaticQuery } from 'gatsby';

import { findNeighbourLetsBeFwendsElements } from '../utils/content';

import { Row, Col } from 'reactstrap';
import Link from '../net/Link';
import { graphql } from 'gatsby';


/**
 * Creates a navigation bar for the mail campaign template.
 * Does so by statically querying the content tree for all
 * lets-be-fwends entries, finds the currently displayed
 * entry by matching paths, and finds neighbours if they exist.
 */
class MailCampaignTemplateNavigation extends React.Component {

  render() {

    return (
      <StaticQuery query={graphql`
      query{
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {path: {regex: "/lets-be-fwends/"}, date: {}, lang: {}}}) {
    edges {
      node {
        id
        excerpt(pruneLength: 250)
        html
        frontmatter {
          date
          path
          featureImage
          title
          abstract
        }
      }
    }
  }}`}

        render={data => {
          let nextLink = undefined;
          let prevLink = undefined;
          let prevTitle = undefined;
          let nextTitle = undefined;

          let neighbours =
            findNeighbourLetsBeFwendsElements(data.allMarkdownRemark.edges,
              this.props.path);

          if (neighbours) {
            if (neighbours.right) {
              prevLink = neighbours.right.node.frontmatter.path;
              prevTitle = neighbours.right.node.frontmatter.title;
            }
            if (neighbours.left) {
              nextLink = neighbours.left.node.frontmatter.path;
              nextTitle = neighbours.left.node.frontmatter.title;

            }
          }


          return (
            <Row className="nav">
              <Col> {
                prevLink ? <Link to={prevLink} className="navlink">← <span className="d-sm-none d-md-inline" dangerouslySetInnerHTML={{ __html: prevTitle }}></span><span className="d-none d-sm-inline d-md-none">previous</span></Link> : null
              }
              </Col>
              <Col className="text-right"> {
                nextLink ? <Link to={nextLink} className="navlink">
                  <span className="d-sm-none d-md-inline" dangerouslySetInnerHTML={{ __html: nextTitle }}></span><span className="d-none d-sm-inline d-md-none">next</span> →</Link> : null
              }
              </Col>
            </Row>)
        }}
      />




    )
  }



}

export default MailCampaignTemplateNavigation;

