export const banners = [
  {
    frontmatter: {
      abstract: "<p>Hi, I'm an Agile Coach and Scrum Master. I support organisations in shaping their way of working to create great digital products. <a href='/articles/towards_a_human_way_of_working/'>I believe there's a better way to do things that benefits all.</a> <span class='d-lg-inline d-none'> I also publish a bi-weekly journal on Agile, Experience Design and their role in the media and technology landscape.<a href='/lets-be-fwends/'> Check it out here.</a></span></p>",
      key: "banner-hi",
      link: {
        target:"/about",
        title: "More About me."
      },
    }
  },
  {
    frontmatter: {
      abstract: "<p>What makes a successful Scrum Master?</p>",
      key: "banner-scrummaster",
      link: {
        target:"/articles/how_i_see_the_role_of_a_scrum_master/",
        title: "A successful team delivering a great product"
      },

    }
  },
  {
    frontmatter: {
      abstract: "<p>Expeditions into the Digital.</p>",
      key: "banner-expeditions",
      link: {
        target:"",
        title: ""
      },

    }
  },
  {
    frontmatter: {
      abstract: "<p>Want a new tool for your practice in your inbox every other week?</p>",
      key: "banner-letsbefwendsarchive",
      link: {
        target:"/lets-be-fwends/",
        title: "Let's be Fwends."
      },

    }
  },
  {
    frontmatter: {
      abstract: "<p>Find all my articles in the archive</p>",
      key: "banner-archive",
      link: {
        target:"/articles",
        title: "Go there."
      },

    }
  },

];