//import moment from 'moment';

import { banners } from './banners';


import moment from 'moment';

export function purge_future_content(content) {

  let temp = [];
  let now = moment();
  for (let i=0; i < content.length; i++) {
    if (now.isSameOrAfter(moment(content[i].node.frontmatter.date))) {
      temp.push(content[i]);
    }
  }

  return temp;

}

export function build_home_content(content) {
  let result = [];

  //copy over the content array, because we will modify it.
  let temp = purge_future_content(content.slice());
  // hoist an article to the top
  let articleIdx = -1;

  for (let i = 0; i < temp.length; i++) {
    
    if (temp[i].node.frontmatter.path.startsWith('/articles/')) {
      articleIdx = i;
      break;
    }
  }

  if (articleIdx > -1) {
    let article = temp[articleIdx];
    temp.splice(articleIdx,1);
    temp.unshift(article);

  }

  temp = temp.slice(0, 15); //no more than 15 entries.


  let used_banner_idx = 0;

  //and then loop over the content array
  for (let i=0; i < temp.length; i++) {

    let blocktype;

    if (i % 2 === 0) {
      blocktype = 'superblock'
    } else { 
      if (Math.random() >= 0.5) {
        blocktype = 'leftblock';
      } else {
        blocktype = 'rightblock';
      }
    }

    let label = undefined;
    if (temp[i].node.frontmatter.path.startsWith('/lets-be-fwends/')) {
      label = 'LBF';
    }
    if (temp[i].node.frontmatter.path.startsWith('/projects/')) {
      label = 'Work';
    }

    result.push({
      node: temp[i].node,
      meta: {
        blocktype,
        label
      }
    });
    //add a banner after 3 content blocks
    if ((i + 1) % 3 === 0) {
      result.push({
        node: { frontmatter: banners[used_banner_idx].frontmatter},
        meta: {
          blocktype: 'bannerblock'
        }
      })
      used_banner_idx++;
    }
  }
  
  return result;
}
