import React from 'react';


const speed = 8; //animation speed, degrees to rotate per scroll event


const blue = { r: 75, g: 100, b:255 };	
const yellow = { r: 255, g: 255, b: 65 };

//const strokeWidth=10;


//let smileArc = "";


const resetAnimationInterval = 40;



/* ************************************************************************************

  Construction of the Eff:

  The Eff consists of two lines (one vertical, one horizontal) and a 60° arc.

  The arc is constructed by positioning a virtual circle at the horizontal middle
  of the graphic, opening a radius, and then taking a 60° part of the resulting
  circle.

  The hinge point of the two lines is positioned relative to the circle center,
  with an offset both to the left and to the bottom.

 ************************************************************************************ */


// General Parameters
// ================================================================================== //
let stroke = 2.923; //stroke width down stroke and arc
let strokeTop = 2.57; //the horizontal top stroke is thinner than the rest.
let strokeAngle = strokeTop; //the stroke of the angle
//note: the value below is what I measure from the source, but it appears to
//be too narrow
//let strokeAngle = 2.435; //the stroke of the angle


// Constructing the circle arc
// ================================================================================== //
let d = 33.697; // circle diameter
let r = d / 2;

let arc = 60; //60°
let arc_start =  180 - (arc / 2);
let arc_end = 180 + (arc / 2);

let cX = r + (stroke / 2); //circle x coord
let cY = r + (stroke / 2); // circle y coord

let smileArc = describeArc(cX,cY,r,arc_start, arc_end); //the actual arc SVG definition


// Constructing the F
// ================================================================================== //

// The F is not centered on the circle, but offset to the left and bottom.
// Reference point is the top of the downstroke

let f_offset_x = -2;
let f_offset_y = 4.62

let f_height = 23.985 - (stroke / 2);
let f_width = 14.771 - (stroke / 2);

/*
  Constructing the lines:
  Since we have different stroke widths for down stroke and side stroke,
  we use two lines.
  The downstroke is from x0/y0 to x1/y1, and the side stroke is x1mod/y1mod x2/y2mod
  The mod is needed to accomodate the differences in strokewidth.
*/

let x0 = r + f_offset_x + (stroke / 2);
let y0 = r + f_offset_y + f_height + (stroke / 2);

let x1 = x0;
let y1 = r + f_offset_y + (stroke / 2);

let x2 = x1 + f_width;
let y2 = y1;

let x1mod = x1 - (stroke/2);
let y1mod = y1 - (stroke - strokeTop);
let y2mod= y2 - (stroke - strokeTop);


// Setting the View Port
// ================================================================================== //

// Depending on wether we want to rotate the arc, we need a larger or smaller viewport

// this is the height of the total construct, that allows for rotation
let svg_width = d + stroke;
let svg_height = r + f_offset_y + f_height + (stroke / 2);

// if we aren't rotating the arc, we can be much smaller:
//let b_x = polarToCartesian(cX,cY,r,arc_start =  180 + (arc / 2)).x - stroke / 4 ;
//let b_y = r + f_offset_y;
//let b_width = x0 - b_x + f_width;
//let b_height = f_height + stroke/2;

let viewbox = "";

// This is the standard viewbox for rotation:
viewbox = "0 0 " + svg_width + " " + svg_height;

// Want the small one? No problem! Remove the comment from that line
//viewbox = b_x + " " + b_y + " " + b_width + " " + b_height;



// Two helpers translating a radius / arc to cartesian coordinates
// ================================================================================== //
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x, y, radius, startAngle, endAngle){

    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;       
}





/**
 * ScrollingEff is used as a loader screen when the content to be shown is not yet available. 
 * It covers the hole screen and shows an auto-rotating Eff. 
 * @class LoadingEff
 * @extends {React.Component}
 *
 * 
 */
class LoadingEff extends React.Component {
  constructor(props) {
    super(props);
    this._resettimeout = null;

    this.getSmileColor = this.getSmileColor.bind(this);

    this.move = this.move.bind(this);
    this.state = {rotation: 0,
      smileColor: blue
    };
  }

  getSmileColor(rotation) {
  let distanceTravelled = Math.abs(rotation / 180);
  let newColors = {};
    if (distanceTravelled > 1 ) { //181° to 360°
      newColors = {
        r: yellow.r - (Math.abs(yellow.r - blue.r) * (distanceTravelled - 1)),
        g: yellow.g - (Math.abs(yellow.g - blue.g) * (distanceTravelled - 1)),
        b: yellow.b + (Math.abs(yellow.b - blue.b) * (distanceTravelled - 1))
      }
    } else { // 0° to 180°
      newColors = {
        r: blue.r + (Math.abs(yellow.r - blue.r) * distanceTravelled),
        g: blue.g + (Math.abs(yellow.g - blue.g) * distanceTravelled),
        b: blue.b - (Math.abs(yellow.b - blue.b) * distanceTravelled)
      }

    }
    return newColors;
  }


  //immediately start working on mount
  componentDidMount() {
    this.move();
  }

  move() {

  let newRot = (this.state.rotation - speed) % 360;
  
  this.setState({rotation: newRot});

    this._resettimeout = setTimeout(() => {
      this.move();
    }, resetAnimationInterval);

    
  }

  //clear the timer on unmount to avoid a setState call to the unmounted component
  componentWillUnmount() {
    clearTimeout(this._resettimeout);
  }



  render() {
    //let RGBblue = "rgb(" + blue.r + "," + blue.g + "," + blue.b + ")";

    
    let transform = "rotate(" + (this.state.rotation) + ", " + cX + ", " + cY + ")";

    let smileColor = this.getSmileColor(this.state.rotation);

    let strokeColor = "rgb(" + 
        Math.round(smileColor.r) + "," + 
        Math.round(smileColor.g) + "," + 
        Math.round(smileColor.b) + ")";
    
    let id="loading-eff";
    if (this.props.inline) {
      id += "-inline";
    }

    return (
            <div id={id}>
          <svg id='eff_svg' version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox={viewbox}
          >
          <line id="eff-vertical-bar" x1={x0} y1={y0} x2={x1} y2={y1} stroke="blue" strokeWidth={stroke} />
          <line id="eff-horizontal-bar" x1={x1mod} y1={y1mod} x2={x2} y2={y2mod} stroke="blue" strokeWidth={strokeTop}/>

          <path d={smileArc} fill="transparent"
            strokeWidth={strokeAngle}
            stroke={strokeColor}
            transform={transform}
      />
          </svg> 
      </div>

    )
  }
}

export default LoadingEff;