import React from 'react';
import MailCampaignTemplateNavigation from '../components/MailCampaignTemplateNavigation';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, Row, Col } from 'reactstrap';
import Link from '../net/Link';
import SubscriptionForm from '../components/SubscriptionForm';
import LoadingEff from '../components/LoadingEff';


class MarkdownCampaignTemplate extends React.Component {

  render() {
    console.log(this.props);

    let { path, data } = this.props;
    const { markdownRemark } = data;
    const { html, frontmatter } = markdownRemark; 
  
    let title = frontmatter.title;
    let abstract = frontmatter.abstract;
    let date = frontmatter.date;
    let image = frontmatter.featureImage;


    let loading = false;
    return (

      <Layout>
        <SEO
          title={title}
          description={abstract}
          image={image}
        />

        <Container fluid className="w-80 newsletter-campaign-view">
          <Row>
            <Col>
              <Link to="/lets-be-fwends/" className="archivelink">To the List Archive</Link>
            </Col>
          </Row>

          <Row>
            <Col><h1 className="newsletter-campaign" dangerouslySetInnerHTML={{ __html: title }}></h1></Col>
          </Row>

          <Row>
            <Col>
              <div className="newsletter-intro">
              <p className="lead">Get a bunch of links into your inbox every other week.</p>
                <p>Let's be Fwends is a journal about agility, organisations, technology, and the larger media landscape. And most importantly the role of all of us in all of that.</p>                
              </div>
            </Col>
            <SubscriptionForm />
          </Row>
          <MailCampaignTemplateNavigation path={this.props.path}/>
          
          <Row>

            <Col className="newsletter-campaign-view-body">
              <div className="campaign-wrapper markdown-campaign" style= {{
                display: "flex",
                justifyContent: "center"}}>
                {loading ? <LoadingEff inline={true} /> : null}
                <div style= {{
                maxWidth:"600px"
              }}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
            </Col>

          </Row>

          <Row>
            <Col>
              <SubscriptionForm />
            </Col>
          </Row>


          <MailCampaignTemplateNavigation path={this.props.path}/>
          

        </Container>
      </Layout>      
    )
  }
}

export default MarkdownCampaignTemplate;


export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        featureImage
        abstract
        downloads {
          to
          text
        }
      }
    }
  }
  `