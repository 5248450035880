import { purge_future_content } from './contentbuilder';

export function findNeighbourLetsBeFwendsElements(content, path, index) {

  let idx;

  let temp = purge_future_content(content);

  //we don't know the index of the element in the content array
  if (!index) {
    //find the index of the current element
    for (let i = 0; i < temp.length; i++) {

      let node = temp[i].node;
      if (node.frontmatter.path === path) {
        idx = i;
      }

    }

  }

  let left = undefined;
  let right = undefined;

  if (idx > 0) {
    left = temp[idx - 1];
  }
  if (idx < content.length - 1) {
    right = temp[idx + 1];
  }

  return { left, right }


}